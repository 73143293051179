/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  SiteVariablesFragment,
  PageMissingPageFragment
} from "src/components/QueryFragments"
import PageMissingPageView from "../views/PageMissingPage"

import "src/assets/scss/material-kit-react.scss"

const PageMissingPage = ({ location }) => {
  const {
    contentfulWebsiteGlobalVariables,
    contentfulPagePageMissing
  } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteGlobalVariables {
          ...SiteVariablesFragment
        }
        contentfulPagePageMissing {
          ...PageMissingPageFragment
        }
      }
    `
  )
  return (
    <PageMissingPageView
      siteVariables={contentfulWebsiteGlobalVariables}
      pageContent={contentfulPagePageMissing}
      location={location}
    />
  )
}

export default PageMissingPage
